var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "default" },
    [
      _c("headbox", {
        attrs: {
          title: "违约结算单",
          customPath: true,
          path: _vm.path,
          query: { active: _vm.active }
        }
      }),
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "loading" },
                [
                  _c(
                    "center",
                    { attrs: { id: "loadingbox" } },
                    [_c("van-loading", { attrs: { size: "68px" } })],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "totle" }, [
                    _c("div", { staticClass: "orderInfo" }, [
                      _c("div", { staticClass: "infoTop flex_between" }, [
                        _c("div", { staticClass: "weight" }, [
                          _c("div", { staticClass: "text" }, [
                            _vm._v("重量(g)")
                          ]),
                          _c("div", { staticClass: "number" }, [
                            _vm._v(_vm._s(_vm.totWeight))
                          ])
                        ]),
                        _c("div", { staticClass: "junJia" }, [
                          _c("div", { staticClass: "text" }, [
                            _vm._v("均价(元/克)")
                          ]),
                          _c("div", { staticClass: "number" }, [
                            _vm._v(_vm._s(_vm.averagePrice))
                          ])
                        ]),
                        _c("div", { staticClass: "jieSuan" }, [
                          _c("div", { staticClass: "text" }, [
                            _vm._v("结算价(元/克)")
                          ]),
                          _c("div", { staticClass: "number" }, [
                            _vm._v(_vm._s(_vm.jieSuan))
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "infoBtm flex_between" }, [
                        _c("div", { staticClass: "baoGuan" }, [
                          _c("div", { staticClass: "text" }, [
                            _vm._v("保管费(元)")
                          ]),
                          _c("div", { staticClass: "number" }, [
                            _vm._v(_vm._s(_vm.storageFee))
                          ])
                        ]),
                        _c("div", { staticClass: "chaJia" }, [
                          _c("div", { staticClass: "text" }, [
                            _vm._v("物料差价(元)")
                          ]),
                          _c("div", { staticClass: "number" }, [
                            _vm._v(_vm._s(_vm.priceDiff))
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "zongJia" },
                          [
                            _c("div", { staticClass: "text" }, [
                              _vm._v("结算金额(元)")
                            ]),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "此费用将累加到押金里",
                                  placement: "top-start"
                                }
                              },
                              [
                                _c("van-icon", {
                                  attrs: {
                                    name: "question-o",
                                    color: "#FF2D2E"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "number",
                                staticStyle: { color: "#FF2D2E" }
                              },
                              [_vm._v(_vm._s(_vm.moneyTotal))]
                            )
                          ],
                          1
                        )
                      ])
                    ]),
                    _c("div", { staticClass: " flex_between orderTime" }, [
                      _c("div", { staticClass: "oddNumber van-ellipsis" }, [
                        _vm._v("定价单号:" + _vm._s(_vm.list.orderNo))
                      ]),
                      _c("div", { staticClass: "oddTime" }, [
                        _vm._v(_vm._s(_vm.list.date))
                      ])
                    ])
                  ]),
                  _vm._l(_vm.list.itemList, function(items) {
                    return _c(
                      "div",
                      {
                        key: items.orderId,
                        staticClass: "box",
                        on: {
                          click: function($event) {
                            return _vm.toDetails(items)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "line" }, [
                          _vm._v(" " + _vm._s(items.name) + " ")
                        ]),
                        _c("div", { staticClass: "boxInfo" }, [
                          _c("div", { staticClass: "boxTop flex_between" }, [
                            _c("div", { staticClass: "boxWeight" }, [
                              _c("div", { staticClass: "boxText" }, [
                                _vm._v("重量(g)")
                              ]),
                              _c("div", { staticClass: "boxNumber" }, [
                                _vm._v(_vm._s(+items.weightZheZu))
                              ])
                            ]),
                            _c("div", { staticClass: "boxPrice" }, [
                              _c("div", { staticClass: "boxText" }, [
                                _vm._v("定价/结算价(元/克)")
                              ]),
                              _c("div", { staticClass: "boxNumber" }, [
                                _vm._v(
                                  _vm._s(items.initPrice) +
                                    "/" +
                                    _vm._s(items.price)
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "boxBaoGuan" }, [
                              _c("div", { staticClass: "boxText" }, [
                                _vm._v("保管费(元)")
                              ]),
                              _c("div", { staticClass: "boxNumber" }, [
                                _vm._v(_vm._s(items.storageFee))
                              ])
                            ]),
                            _c("div", { staticClass: "boxCj" }, [
                              _c("div", { staticClass: "boxText" }, [
                                _vm._v("物料差价(元)")
                              ]),
                              _c("div", { staticClass: "boxNumber" }, [
                                _vm._v(
                                  _vm._s(
                                    items.priceDiff == "" ? 0 : items.priceDiff
                                  )
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "boxBtm flex_between" }, [
                            _c(
                              "div",
                              { staticClass: "oddNumber van-ellipsis" },
                              [_vm._v("定价单号:" + _vm._s(items.orderId))]
                            ),
                            _c("div", { staticClass: "oddTime" }, [
                              _vm._v(_vm._s(_vm.list.date))
                            ])
                          ])
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }