<template>
  <div class="default">
      <headbox title="违约结算单" :customPath="true" :path="path" :query="{active:active}"></headbox>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="min-height:100vh" >
      <div class="loading" v-if="isLoading">
      <center id="loadingbox">
        <van-loading size="68px" />
      </center>
      </div>
    <div v-if="!isLoading">
            <div class="totle">
                <div class="orderInfo">
                      <div class="infoTop flex_between">
                        <div class="weight">
                          <div class="text">重量(g)</div>
                          <div class="number">{{totWeight}}</div>
                        </div>
                        <div class="junJia">
                          <div class="text">均价(元/克)</div>
                          <div class="number">{{averagePrice}}</div>
                        </div>                
                        <div class="jieSuan">
                          <div class="text">结算价(元/克)</div>
                          <div class="number">{{jieSuan}}</div>
                        </div>
                      </div>
                      <div class="infoBtm flex_between">
                        <div class="baoGuan">
                          <div class="text">保管费(元)</div>
                          <div class="number">{{storageFee}}</div>
                        </div>
                        <div class="chaJia">
                          <div class="text">物料差价(元)</div>
                          <div class="number">{{priceDiff}}</div>
                        </div>                
                        <div class="zongJia">
                          <div class="text">结算金额(元)</div>
                           <el-tooltip class="item" effect="dark" content="此费用将累加到押金里"  placement="top-start">
                                    <van-icon name="question-o" color="#FF2D2E"  />
                              </el-tooltip>     
                          <div class="number" style="color:#FF2D2E">{{moneyTotal}}</div>
                        </div>
                      </div>
                </div>
                  <div class=" flex_between orderTime">
                        <div class="oddNumber van-ellipsis">定价单号:{{list.orderNo}}</div>
                        <div class="oddTime">{{list.date}}</div>
                  </div>
          </div>
          <div class="box" v-for="(items) in list.itemList" :key="items.orderId" @click="toDetails(items)">
            <div class="line">
              {{ items.name }}
            </div>
            <div class="boxInfo" >
                  <div class="boxTop flex_between">
                    <div class="boxWeight">
                      <div class="boxText">重量(g)</div>
                      <div class="boxNumber">{{+items.weightZheZu}}</div>
                    </div>
                    <div class="boxPrice">
                      <div class="boxText">定价/结算价(元/克)</div>
                      <div class="boxNumber">{{items.initPrice}}/{{items.price}}</div>
                    </div>
                    <div class="boxBaoGuan">
                      <div class="boxText">保管费(元)</div>
                      <div class="boxNumber">{{items.storageFee}}</div>
                    </div>
                    <div class="boxCj">
                      <div class="boxText">物料差价(元)</div>
                      <div class="boxNumber">{{items.priceDiff == ''?0:items.priceDiff}}</div>
                    </div>
                  </div>
                  <div class="boxBtm flex_between">
                        <div class="oddNumber van-ellipsis">定价单号:{{items.orderId}}</div>
                        <div class="oddTime">{{list.date}}</div>
                  </div>
            </div>
          </div>
    </div>
      </van-pull-refresh>
  </div>
</template>

<script>
import headbox from '@/components/head.vue'
import *as api from'@/utils/api'
export default {
  components:{
    headbox
  },
  data() {
    return {
      list: {},
      refreshing: false,
      totWeight:0, //总重
      averagePrice:0,//均价
      jieSuan:0,//结算价
      storageFee:0,//保管费
      priceDiff:0, //物料差价
      moneyTotal:0,//总价
      isLoading:true, //加载状态
      path:'',//跳转的路径
      type:'',
      active:'',
    };
  },
async  mounted() {
          this.type  =  this.$route.query.type

        let res = await api.queryJieSuanOrderDetail({
          orderId:this.$route.query.orderid
        })
        this.list = res.data
        this.processingData(this.list)
        if (this.type == 'buy') {
            this.path = 'buyOrder'
          this.active = 'buyJieSuan'

        }else{
          this.path = 'sellSettlement'
          this.active = 'Settlement'
        }
  },
  methods: {
    toDetails(item){
      if (this.type == 'buy') {
        this.$router.push({
        name: "buyOrderParticulars",
        query: {order:item.orderId}
      });
      }else{
      this.$router.push({
        name: "sellPricingDetails",
        query: {id:item.orderId}
      });
      }

        console.log(item);
    },
async  onRefresh() {
    let res = await api.queryJieSuanOrderDetail({
          orderId:this.$route.query.orderid
        })
        this.list = res.data
      this.refreshing = false
    },
       processingData(data){
        let TotalWeight = 0
        let averagePrice = 0
        let baoGuan = 0
        let jieSuan = 0
        let priceDiff = 0
        let moneyTotal = 0
        data.itemList.forEach(item=>{
          TotalWeight += +item.weightZheZu
          averagePrice += (+item.weightZheZu * +item.initPrice)
          baoGuan += +item.storageFee
          jieSuan = +item.price
          priceDiff += item.priceDiff == ''?0:+item.priceDiff
          moneyTotal = (-baoGuan) + priceDiff
          this.totWeight = +TotalWeight
          this.averagePrice = ((+averagePrice)/TotalWeight).toFixed(item.name.includes('白银') ? 3 : 2)
          this.storageFee = -baoGuan
          this.jieSuan = jieSuan
          this.priceDiff = priceDiff
          this.moneyTotal = moneyTotal
          this.isLoading = false
          console.log('item',item);
        })
       },
  },
};
</script>

<style lang="scss" scoped>

::v-deep{
  .van-pull-refresh{
    background-color: #f5f5f5;
    padding: 20px 32px;
    }
    .van-loading__text{
        font-size: 30px;
    }
    .van-list__finished-text{
        font-size: 30px;
    }
    .van-pull-refresh__head{
        font-size: 30px !important;
    }
    .el-tooltip{
       position: absolute;
       top: -8px;
       right: 0;
       
    }
}

.totle{
  height: 320px;
  background-color: #fff;
  border-radius: 8px;
  padding: 32px 0px;
  .orderInfo{
    padding: 0 30px;
    .infoBtm{
      margin-top: 40px;
        div{
          width: 170px;
        }
    }
    .infoTop{
        div{
          width: 170px;
        }
    }
      .text{
        height: 24px;
        font-size: 24px;
        color: #999999;
        line-height: 24px;
        margin-bottom: 12px;
  
      }
      .number{
        height: 32px;
        line-height: 32px;
        font-size: 32px;
        color: #242424;
      }

  }
}
.box{
  width: 100%;
  height: 208px;
  margin: 30px 0;
  display: flex;
  .line{
      width: 40px;
      height: 100%;
      border-radius: 8px 0px 0px 8px;
      background: #CC0E0F;
      text-align: center;
      line-height: initial;
      padding: 46px 0;
      color: #fff;
  }
  .boxInfo{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 0px 8px 8px 0px;
    padding: 30px;
    .boxTop{
      display: flex;
      padding-bottom: 30px;
      border-bottom: 1px solid #E9E9E9;
        .boxText{
          font-size: 24px;
          color: #666666;
          margin-bottom: 14px;
        }
        .boxNumber{
          font-size: 30px;
          color: #242424;
        }
    }
    .boxBtm{
      padding: 16px 0;
      font-size: 24px;
      color: #999999;
      .oddNumber{
        width: 320px;
      }
    }
  }
}
.flex_between{
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.orderTime{
  padding: 20px 30px 0;
  margin-top: 30px;
  border-top: 1px solid #E9E9E9;
  font-size: 24px;
  color: #999999;
.oddNumber {
  width: 360px;
}
}
.loading{
  margin-top:100px;
}
.zongJia,.jieSuan{
  width: 200px !important;
  position: relative;
        .van-icon{
          font-size: 32px;
        }
}
</style>